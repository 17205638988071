import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/meta/devops.png'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import IllustrationBlock from '../components/blocks/IllustrationBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import MosaicBlock from '../components/blocks/MosaicBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Box from '../components/primitives/Box'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/developer-productivity/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/lead/dora.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/dora-purple.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/slack-purple.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/surveys-purple.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`

const DevOpsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="DevOps metrics for modern software organizations"
      variant="dark"
      isNew
      description="Measure and improve proven DevOps metrics including DORA and SPACE."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Measure and improve DevOps&nbsp;metrics</>}
        content="Swarmia makes it easy for you to track proven DevOps metrics, including DORA and SPACE."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading="See the whole organization’s DevOps metrics at a glance"
        content="Understand how different groups and teams are doing compared to the whole organization. This way, engineering leaders and DevOps teams can proactively offer help to the teams that might need it."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <QuoteBlock person="silvia" />
      <LeadBlock
        heading="Drive meaningful change with DevOps metrics"
        content="Drill deeper into team-level metrics and adopt working agreements in Swarmia to put continuous improvement on autopilot."
      />
      <MosaicBlock
        title="Drill down to see DORA metrics for teams"
        content="Swarmia allows you to accurately and automatically measure the four DORA metrics: deployment frequency, change lead time, change failure rate, and mean time to recovery."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        imageFrame={false}
      />
      <MosaicBlock
        title="Systematically improve each team’s DevOps metrics"
        content={
          <>
            Measuring is one thing. But when your goal is to improve DevOps
            metrics, you can set team-wide working agreements in Swarmia to
            support those goals.
            <br />
            <br />
            Teams and individuals can subscribe to contextual Slack
            notifications, allowing them to stay on top of their working
            agreements.
          </>
        }
        image={getImage(data.mosaicLeft)!}
        imageFrame={false}
      />
      <MosaicBlock
        title={<>Go beyond traditional DevOps&nbsp;metrics</>}
        content={
          <>
            When you’re introducing metrics to your engineering organization,
            DevOps metrics are a great place to start.
            <br />
            <br />
            But once you’re happy with your performance across the basic
            metrics, Swarmia allows you to expand into other possible
            improvement areas, from developer experience surveys to cross-team
            initiatives, and more.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
        imageFrame={false}
      />
      <Box>
        <CTABlock
          title={<>Start measuring and improving DevOps&nbsp;metrics today.</>}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/dora-metrics/',
            '/blog/engineering-benchmarks/',
            '/blog/space-framework/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default DevOpsPage
